import { Button, TextField, Typography } from "@mui/material";
import done_icon from "../../assets/done_step.svg";
import { useState } from "react";
import { useCreateSubscriptionMutation } from "../../api/apiBlog";
import { useMixpanelPublic } from "../../helpers/mixpanel";
import dayjs from "dayjs";

type SubscriptionBody = {
  email: string;
  blogId?: number;
  referrer?: string;
};

interface SubscriptionInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  subscriptionBody: SubscriptionBody;
}

const SubscriptionInput = ({
  email,
  setEmail,
  subscriptionBody,
}: SubscriptionInputProps) => {
  const [isClickedSubscribe, setIsClickedSubscribe] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [createSubscription, createSubscriptionResult] =
    useCreateSubscriptionMutation();
  const mixpanel = useMixpanelPublic();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const checkEmailValid = (email: string) => {
    return email && emailRegex.test(email) && email.length <= 40;
  };

  const onChangeEmail = (email: string) => {
    setIsClickedSubscribe(false);
    setIsEmailValid(false);
    if (checkEmailValid(email)) {
      setEmail(email);
      return;
    }
    setEmail("");
  };

  const onClickedSubscribe = () => {
    setIsClickedSubscribe(true);
    if (email) {
      setIsEmailValid(true);
      createSubscription(subscriptionBody);
      const date = new Date();
      mixpanel("subscribed_to_blog", {
        email: email,
        pageURL: window.location.href,
        dateTime: dayjs(date).format("MM/DD/YYYY"),
        time: dayjs(date).format("HH:mm:ssZ[Z]"),
      });
    }
  };

  const validSubscribe = isClickedSubscribe && isEmailValid;

  const NEWS_LETTER_DESCRIPTION = `Cache is shaping the future of finance using automation & AI. Subscribe to get personal finance news & tips in your inbox.`;

  return (
    <div>
      <Typography className="newletter_text" id="posts">
        {NEWS_LETTER_DESCRIPTION}
      </Typography>
      <div className="layout_newsletter_input">
        <div className="layout_email_newsletter_input">
          <div className="layout_background_email_newsletter">
            <TextField
              id="outlined-basic"
              className="email_newsletter_text_field"
              fullWidth
              placeholder="Email"
              onChange={(event) => onChangeEmail(event.target.value)}
            />
            <Button
              className="newsLetter_button"
              variant="contained"
              onClick={onClickedSubscribe}
              disabled={isClickedSubscribe && isEmailValid}
            >
              {!validSubscribe ? (
                "Subscribe"
              ) : (
                <img src={done_icon} alt="done icon" />
              )}
            </Button>
          </div>
          {isClickedSubscribe && !isEmailValid && (
            <Typography className="subscribe_error" id="posts">
              Please enter a valid e-mail address.
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
export default SubscriptionInput;
