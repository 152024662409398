import { Box, Typography } from "@mui/material";
import "./blogPostCard.css";
import { Link } from "react-router-dom";

interface BlogPostCardProps {
  title: string;
  author: string;
  description: string;
  image: string;
  slugTitle: string;
}

const BlogPostCard = ({
  title,
  author,
  description,
  image,
  slugTitle,
}: BlogPostCardProps) => {
  function truncateText(text: string, maxLength: number) {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }
  return (
    <Box>
      <Link className="post_link_card" to={`${slugTitle}`}>
        <Typography className="title_post_card" id="title_post_card">
          {title}
        </Typography>
        <Typography className="author_name_card" id="author_name_card">
          {author}
        </Typography>
        <div className="layout_post_card">
          <img className="image_post_card" src={image} alt="image_post" />
          <div className="layout_content_post_card">
            <Typography
              className="description_post_card"
              id="description_post_card"
            >
              {truncateText(description, 320)}
            </Typography>
            <div className="layout_post_link_card">
              <span>{`Read Post >`} </span>
            </div>
          </div>
        </div>
      </Link>
    </Box>
  );
};

export default BlogPostCard;
