import { useMixpanel } from "../../helpers/mixpanel";
import PlaidAuth from "../Plaid/PlaidAuth";
import Button from "../../elements/Button/Button";
import connected_acc_icon from "../../assets/connected_acc_icon.svg";

interface AccountSettingsProps {
  account: any;
  relationType: string;
  reloadPage: () => void;
  disconnectAccountHandler: (account: any) => void;
}

const AccountSettings = ({
  account,
  relationType,
  reloadPage,
  disconnectAccountHandler,
}: AccountSettingsProps) => {
  const mixpanel = useMixpanel();

  const connectIconButton = (
    <button onClick={() => disconnectAccountHandler(account)}>
      {<img src={connected_acc_icon} alt="Connected Icon" />}
    </button>
  );

  const reconnectNeededButton = (
    <PlaidAuth
      accessToken={account.accessToken}
      reloadPage={reloadPage}
      relationType={relationType}
    >
      <Button
        type="warning-btn"
        onClick={() => {
          mixpanel("Clicked_Reconnect_Now", {
            "Origination point": "settings",
          });
        }}
      >
        Reconnect Now
      </Button>
    </PlaidAuth>
  );

  const button =
    account.status === "reconnection_need"
      ? reconnectNeededButton
      : connectIconButton;

  return (
    <div key={account?.plaidAccountId} className="accounts__infoContainer">
      <div
        className={
          account.status === "reconnection_need"
            ? "account__info__data disconnected"
            : "account__info__data "
        }
      >
        {account.institution.name} <span>{account.mask}</span>
      </div>
      {button}
    </div>
  );
};

export default AccountSettings;
