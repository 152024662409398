import { Button } from "@mui/material";
import "./banner.css";
import avocadoToast from "../../assets/avocado-toast.png";

interface BannerProps {
  hasImage?: boolean;
  text: string;
  onClick?: () => void;
}
const Banner = ({ hasImage, text, onClick }: BannerProps) => {
  return (
    <Button
      style={{
        padding: "0px 0px 0px 0px",
        color: "#303030",
        textTransform: "none",
      }}
      onClick={onClick}
    >
      <div className="banner_box">
        {hasImage && (
          <img src={avocadoToast} style={{ width: "55px", height: "55px" }} />
        )}
        <p className="banner_text_button">{text}</p>
      </div>
    </Button>
  );
};

export default Banner;
