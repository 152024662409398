import AccountSettings from "./AccountSettings";

interface ConnectedAccountsListProps {
  checkingAccounts: any;
  savingsAccounts: any;
  reloadPage: () => void;
  disconnectAccountHandler: (account: any) => void;
}

const ConnectedAccounts = ({
  checkingAccounts,
  savingsAccounts,
  reloadPage,
  disconnectAccountHandler,
}: ConnectedAccountsListProps) => {
  const checkingAccountsList = checkingAccounts.map(
    (account: any, index: number) => {
      if (account.cacheType === "savings") {
        return;
      }
      return (
        <AccountSettings
          key={index}
          account={account}
          relationType="checking"
          reloadPage={reloadPage}
          disconnectAccountHandler={disconnectAccountHandler}
        />
      );
    },
  );

  const savingsAccountList = [...checkingAccounts, ...savingsAccounts].map(
    (account: any, index: number) => {
      if (account.cacheType === "checking") {
        return;
      }
      return (
        <AccountSettings
          key={index}
          account={account}
          relationType="savings"
          reloadPage={reloadPage}
          disconnectAccountHandler={disconnectAccountHandler}
        />
      );
    },
  );

  return (
    <div>
      <div className="block_accounts" id="connected-accounts">
        <div className="settings__title">Connected Accounts</div>
        <div className="profile__blockInfo">
          <div className="profile__title">Checking Accounts</div>
          {checkingAccountsList}
        </div>
        <div className="profile__blockInfo">
          <div className="profile__title">Savings Accounts</div>
          {savingsAccountList}
        </div>
      </div>
    </div>
  );
};

export default ConnectedAccounts;
