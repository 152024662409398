import Dialog from "@mui/material/Dialog";

import closeBtn from "../../assets/closeBtn.png";
import BankCard from "../BankCard/BankCard";
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import { useMixpanel } from "../../helpers/mixpanel";
import { AccountType } from "../../helpers/types";

interface ModalAccountsListProps {
  accountsList: any;
  setAccount: React.Dispatch<any>;
  open: boolean;
  onClose: () => void;
  title: string;
  relationType: string;
  verifyAccountsSubtype: (accounts: any[], institution: any) => void;
  accountLoadErrorCallback?: (errorMsg: string, type: string) => void;
  onPlaidAuthSuccess: (accounts: any) => void;
  setIsPlaidAuthLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  fadeOut: boolean;
}

const ModalAccountsList = ({
  accountsList,
  setAccount,
  open,
  onClose,
  title,
  verifyAccountsSubtype,
  accountLoadErrorCallback,
  onPlaidAuthSuccess,
  setIsPlaidAuthLoading,
  relationType,
  fadeOut
}: ModalAccountsListProps) => {
  const mixpanel = useMixpanel();
  const onSelectHandler = (item: any) => {
    if (!item.isUsedInAutomation) {
      setAccount(item);
      if (relationType === AccountType.CHECKING) {
        mixpanel("Connected_checking_account", { Banks: item });
      } else if (relationType === "savings") {
        mixpanel("Connected_savings_account", { Banks: item });
      }
      onClose();
    } else {
      return;
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{
        root: "add-automation-dialog",
        paper: "add-automation-dialog-paper",
      }}
    >
      <div className="closeModalBtn">
        <img src={closeBtn} alt="close button" onClick={onClose} />
      </div>
      <div className="text-center" style={{ width: "100%" }}>
        <PlaidAuth
          accountType={`Add New ${title}`}
          typeBtn="modalPlaid-btn"
          success={onPlaidAuthSuccess}
          setIsLoading={setIsPlaidAuthLoading}
          preConnectedAccountsCB={verifyAccountsSubtype}
          relationType={relationType}
          accountLoadErrorCallback={accountLoadErrorCallback}
        />
      </div>
      <div className="modalAccountsList__title">{`Available ${title}s`}</div>
      <div className="modalAccountsList__cardsHolder">
        {accountsList?.map((item: any) => (
          <BankCard
            fadeOut={fadeOut}
            key={item.plaidAccountId}
            item={item}
            selectHandler={onSelectHandler}
          />
        ))}
      </div>
    </Dialog>
  );
};

export { ModalAccountsList };