import { useParams } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import Header from "../../elements/Header";
import SubHeader from "../../elements/SubHeader/SubHeader";
import Footer from "../../elements/Footer";
import { Link } from "react-router-dom";
import "./blogPost.css";
import FaqSection from "../../elements/Faq/FaqSection";
import { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import EmailLogo from "../../assets/social-images/email_logo.svg";
import FacbookLogo from "../../assets/social-images/facebook_logo_social.svg";
import LinkedinLogo from "../../assets/social-images/linkedin_logo_social.svg";
import TwitterLogo from "../../assets/social-images/twitter_logo_social.svg";
import WhatsappLogo from "../../assets/social-images/whatsapp_logo_social.svg";
import { POSTS } from "../../helpers/BlogPosts";
import { parseTextWithBold, parseTextWithLinks } from "../../helpers/utils";
import SubscriptionInput from "../../elements/Subscription/SubscriptionInput";

interface Section {
  type: string;
  content?: string;
  items?: { content: string; strong?: string }[];
}
interface PostContentProps {
  sections: Section[];
}

interface Post {
  id: number;
  slug: string;
  title: string;
  author: string;
  image: string;
  imageCopyright: string;
  description: string;
  priorityRead: boolean;
  sections: Section[];
}

const BlogPost = () => {
  const { postId } = useParams();
  const [email, setEmail] = useState("");
  const post: Post = POSTS.filter((post) => postId === post.slug)[0];

  const subtitle = (
    <Box className="blog_subtitle">
      Learn more about Cache product updates, personal finance, and ways to
      optimize your finances.
    </Box>
  );

  const recommendedPosts = POSTS.filter(
    (post) => post.slug !== postId && post.priorityRead,
  )
    .map((post, index) => (
      <RecommendedPostCard
        key={index}
        image={post.image}
        title={post.title}
        id={post.slug}
      />
    ))
    .slice(0, 3);

  const socialLogos = (
    <div className="social_logos">
      <EmailShareButton url={window.location.href}>
        <img className="email_logo" src={EmailLogo} alt="email_logo" />
      </EmailShareButton>
      <FacebookShareButton url={window.location.href}>
        <img className="social_logo" src={FacbookLogo} alt="facebook_logo" />
      </FacebookShareButton>
      <WhatsappShareButton url={window.location.href}>
        <img className="social_logo" src={WhatsappLogo} alt="whatsapp_logo" />
      </WhatsappShareButton>
      <TwitterShareButton url={window.location.href}>
        <img className="social_logo" src={TwitterLogo} alt="twitter_logo" />
      </TwitterShareButton>
      <LinkedinShareButton url={window.location.href}>
        <img className="social_logo" src={LinkedinLogo} alt="linkedin_logo" />
      </LinkedinShareButton>
    </div>
  );

  return (
    <div>
      <Header position="sticky" />
      <SubHeader title_1="Cache" title_2=" Blog" subtitle={subtitle} />
      <Box className="whiteBox">
        <Box>
          <Container>
            <div className="layout_post">
              <SubscriptionInput
                email={email}
                setEmail={setEmail}
                subscriptionBody={{
                  referrer: window.document.referrer || "N/A",
                  blogId: post.id,
                  email: email,
                }}
              />
              <Link className="blog_main_link" to={`/blog`}>
                <span>{`< All posts`} </span>
              </Link>
              <div className="layout_title_post">
                <Typography className="title_post" id="posts">
                  {post.title}
                </Typography>
                <Typography className="author_name_post" id="posts">
                  {post.author}
                </Typography>
              </div>
              <div className="image_block_post">
                <div className="image_layout_post">
                  <img
                    className="image_post"
                    src={post.image}
                    alt="image_post"
                  />
                </div>
                <Typography className="image_copyright">
                  {post.imageCopyright}
                </Typography>
              </div>

              <div className="layout_social_logos">{socialLogos}</div>
              <div className="text_post" id="posts">
                <PostContent sections={post.sections} />
              </div>
              <Typography className="title_post" id="posts">
                Recommended Posts
              </Typography>
              <div className="layout_recommended_posts">{recommendedPosts}</div>
            </div>
          </Container>
        </Box>
      </Box>
      <Box className="blackBox" textAlign="center">
        <FaqSection />
      </Box>
      <Footer />
    </div>
  );
};

export default BlogPost;

interface RecommendedPostCardProps {
  image?: string;
  title: string;
  id: string;
}
const RecommendedPostCard = ({
  image,
  title,
  id,
}: RecommendedPostCardProps) => {
  return (
    <div className="recommended_card" key={id}>
      <Link className="recommended_post_link_card" to={`/blog/${id}`}>
        <img className="recommended_image_post" src={image} alt="image_post" />
        <div className="layout_recommended_title">
          <Typography className="recommended_title_post" id="title_post_card">
            {title}
          </Typography>
        </div>
        <div className="layout_post_link_card">
          <span>{`Read Post >`} </span>
        </div>
      </Link>
    </div>
  );
};

const PostContent = ({ sections }: PostContentProps) => {
  return sections.map((section, index) => (
    <div key={index} className="blog_post_conent">
      {section.type === "paragraph" && <p>{section.content}</p>}
      {section.type === "header" && (
        <p>
          <strong>{section.content}</strong>
        </p>
      )}
      {section.type === "p-bold" && (
        <p>{parseTextWithBold(section.content || "")}</p>
      )}
      {section.type === "underline" && (
        <p className="post_content_underline">
          <strong>{section.content}</strong>
        </p>
      )}

      {section.type === "p-link" && (
        <p>{parseTextWithLinks(section.content || "")}</p>
      )}

      {section.type === "list" && (
        <ul>
          {section.items?.map((item, idx) => (
            <li key={idx}>
              <strong>{item.strong ? item.strong : ""}</strong>
              {item.content}
            </li>
          ))}
        </ul>
      )}
      <br />
    </div>
  ));
};
