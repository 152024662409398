import { Box, Container, Typography, Pagination } from "@mui/material";
import Footer from "../../elements/Footer";
import Header from "../../elements/Header";
import SubHeader from "../../elements/SubHeader/SubHeader";
import BlogPostCard from "../../elements/Blog/BlogPostCard";
import "./blog.css";
import FaqSection from "../../elements/Faq/FaqSection";
import { POSTS } from "../../helpers/BlogPosts";
import SubscriptionInput from "../../elements/Subscription/SubscriptionInput";
import { useState } from "react";
import { createSlug } from "../../helpers/utils";

const POSTS_PER_PAGE = 10;
const DEFUALT_BLOG_ID_ALL_PAGES = 0;
const DEFUALT_BLOG_PAGE = 1;

const Blog = () => {
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(1);

  const subtitle = (
    <Box className="blog_subtitle">
      Learn more about Cache product updates, personal finance, and ways to
      optimize your finances.
    </Box>
  );

  const posts = POSTS.map((post, index) => (
    <BlogPostCard
      key={index}
      title={post.title}
      author={post.author}
      image={post.image}
      description={post.description}
      slugTitle={createSlug(post.title)}
    />
  ));

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    pageValue: number,
  ) => {
    setPage(pageValue);
  };

  return (
    <div>
      <Header position="sticky" />
      <SubHeader title_1="Cache" title_2=" Blog" subtitle={subtitle} />
      <Box className="whiteBox">
        <Box>
          <Container>
            <div className="layout_posts">
              <SubscriptionInput
                email={email}
                setEmail={setEmail}
                subscriptionBody={{
                  referrer: window.document.referrer || "N/A",
                  blogId: DEFUALT_BLOG_ID_ALL_PAGES,
                  email: email,
                }}
              />
              <Typography className="main_title_post" id="posts">
                All Posts
              </Typography>
              {posts.slice((page - 1) * POSTS_PER_PAGE, page * POSTS_PER_PAGE)}
              <div className="pagination-blog">
                <Pagination
                  className="pagination-blog"
                  color="primary"
                  defaultPage={DEFUALT_BLOG_PAGE}
                  onChange={handlePageChange}
                  count={Math.ceil(posts.length / POSTS_PER_PAGE)}
                  page={page}
                />
              </div>
            </div>
          </Container>
        </Box>
      </Box>
      <Box className="blackBox" textAlign="center">
        <FaqSection />
      </Box>
      <Footer />
    </div>
  );
};

export default Blog;
