import getBankLogo from "../../helpers/getBankIcon";
import "./bankCard.css";
import { Tooltip } from "@mui/material";

type Item = {
  isActive: boolean;
  mask: number;
  accountValue: number;
};
type AccountCardProbs = {
  item: Item;
  selectHandler: (item: any) => void;
  fadeOut: boolean;
};

function BankCard({ item, selectHandler, fadeOut }: any) {
  const { isActive, mask, availableBalance } = item;
  const bankName = item?.institution?.name;
  const bankLogo = getBankLogo(item?.institution?.logo, item?.institution?.id);
  const accountMask = item?.mask;
  const isUsedInAutomation = item.isUsedInAutomation;
  return fadeOut ? (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            textAlign: "left",
            color: "#EEEEEE",
            fontWeight: 400,
            width: "288px",
            lineHeight: "16px",
            border: "1px solid #000",
          },
        },
        arrow: {
          sx: {
            "&:before": {
              border: "1px solid #000",
            },
          },
        },
      }}
      enterTouchDelay={0}
      title={
        isUsedInAutomation
          ? "This account is already associated with an active automation. Please select another account."
          : ""
      }
      arrow
    >
      <div
        className={`bankCard ${isUsedInAutomation ? "bankCard-used" : ""}`}
        onClick={() => selectHandler(item)}
      >
        <div className="bankCard__info">
          <div className="bankCard__bankName">{bankName}</div>
          <div className="bankCard__number">{accountMask}</div>
        </div>
        <div className="bankCard__bankLogo">
          <img src={bankLogo} alt="Bank Logo" />
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className={`bankCard`} onClick={() => selectHandler(item)}>
      <div className="bankCard__info">
        <div className="bankCard__bankName">{bankName}</div>
        <div className="bankCard__number">{accountMask}</div>
      </div>
      <div className="bankCard__bankLogo">
        <img src={bankLogo} alt="Bank Logo" />
      </div>
    </div>
  );
}

export default BankCard;
